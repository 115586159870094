import React from "react"

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { Box, Container, Typography } from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import { NoSsr } from '@material-ui/core';
const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 7,
    height:'40px',
    width:'80px'
  }));

const Toggle = () => {
    const [last, setLast] = React.useState(null)
    const [state, setState] = React.useState({
        one: false,
        two: false,
        three: false,
    });
    
    const handleChange = (event) => {
        setState({
        ...state,
        [event.target.name]: event.target.checked,
        });
        setLast(event.target.name);
    };
    
    React.useEffect(() => {
        if(state.one && state.two && state.three) {
            console.log('all checked')
            if(last == "one") {
                console.log('one')
                setState({...state, three: false})
            }
            else if(last == "two") {
                console.log('one')
                setState({...state, one: false})
            }
            else if(last == "three") {
                console.log('one')
                setState({...state, two: false})
            }
        }
    }, [state.one, state.two, state.three])
    return (
        <NoSsr>
        <Container>
        <Box display="flex" justifyContent="center" alignItems="center" style={{height:'70vh', padding:'50px 0', textAlign:'center'}}>
        <FormControl component="fieldset" variant="standard">
        <FormGroup>
            <FormControlLabel
            control={<Switch checked={state.one} onChange={handleChange} name="one" color="primary" /> }
            label={<Typography variant={"body1"} style={{fontSize:'20px'}}>Hyvä asiakaskokemus</Typography>}
            />
            <FormControlLabel
            control={<Switch checked={state.two} onChange={handleChange} name="two"  color="primary"  /> }
            label={<Typography variant={"body1"} style={{fontSize:'20px'}}>Hyvä henkilöstökokemus</Typography>}
            />
            <FormControlLabel
            control={ <Switch checked={state.three} onChange={handleChange} name="three"  color="primary" /> }
            label={<Typography variant={"body1"} style={{fontSize:'20px'}}>Alhaiset kiinteät kulut</Typography>}
            />
        </FormGroup>
        </FormControl>
        </Box>
        </Container>
        </NoSsr>
    );
}

export default Toggle;